import Header from "../../components/Headers/Header";
import React, { useEffect, useRef, useState } from "react";
import {toastr} from 'react-redux-toastr';
import { Button, Card, CardHeader, Col, Container, FormGroup, Input, Modal, Row, Table } from "reactstrap";
import Select from "react-select";
import general_fetch from "../../utils/fetch";
import { colourStyles, colourStyles1, colourStyles3 } from "../../utils/react_select_styles";
import { DatePicker, Tooltip } from "antd";
import makeAnimated from 'react-select/animated';
import dayjs from 'dayjs';
import generic_sort from "../../utils/generic_sort";
import { set_csp_filter } from "../../utils/set_csp_filter";
import { get_csp_filter } from "../../utils/get_csp_filter";

const animatedComponents = makeAnimated();

const styles = {
	header_row: {
		marginLeft: 0,
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	round_status: {
		fontSize: 'small',
		paddingBottom: 0
	},
	nav_pills_rounds: {
		paddingRight: 60,
		marginRight: "0.7rem",
		marginLeft: "0.2rem",
	},
	action_button: {
		margin: 1       
	}
};

const infurnia_domains = [{value: 'interior', label: 'Modular Interiors'}, {value: 'architecture', label: 'Architecture & Interiors'}]

const AddBdRep = ({open, toggle, fetch_bd_reps, cs_org_users, mode, active_bd_rep}) => {
	const [selected_user, set_selected_user] = useState();
	const [selected_domain, set_selected_domain] = useState();
	const [start_time, set_start_time] = useState('4:30');
	const [end_time, set_end_time] = useState('13:30');
	const [valus, setValues] = useState([
		dayjs("00:00:00", "HH:mm"),
		dayjs("00:00:00", "HH:mm")
	  ]);

	const add_bd_rep = async() => {
		try{	
			if(!start_time || !end_time || !(start_time.split(':') && start_time.split(':').length==2) || !(end_time.split(':') && end_time.split(':').length==2)){
				toastr.error('Start/end time is invalid')
			}
			let [start_time_hrs, start_time_minutes] = start_time.split(':').map(Number)
			let start_time_offset_in_min = start_time_hrs * 60 + start_time_minutes 

			let [end_time_hrs, end_time_minutes] = end_time.split(':').map(Number)
			let end_time_offset_in_min = end_time_hrs * 60 + end_time_minutes 
			console.log('start_time_offset_in_min', start_time, start_time_offset_in_min, end_time, end_time_offset_in_min)
			let resp = await general_fetch({url:'bd_representative/add', body: {bd_user_id: selected_user.value, domain: selected_domain.value, start_time_offset_in_min, end_time_offset_in_min}}) 
			onClick_handleClose()
			fetch_bd_reps()
			toastr.success('Successfully added BD Rep')
		}catch(e){
			console.error(e)
			toastr.error(`Couldn't add BD Rep`)
		}
	}

	const update_bd_rep = async() => {
		try{	
			if(!start_time || !end_time || !(start_time.split(':') && start_time.split(':').length==2) || !(end_time.split(':') && end_time.split(':').length==2)){
				toastr.error('Start/end time is invalid')
			}
			let [start_time_hrs, start_time_minutes] = start_time.split(':').map(Number)
			let start_time_offset_in_min = start_time_hrs * 60 + start_time_minutes 

			let [end_time_hrs, end_time_minutes] = end_time.split(':').map(Number)
			let end_time_offset_in_min = end_time_hrs * 60 + end_time_minutes 
			console.log('start_time_offset_in_min', start_time, start_time_offset_in_min, end_time, end_time_offset_in_min)
			let resp = await general_fetch({url:'bd_representative/update', body: {ids: active_bd_rep.id, domain: selected_domain.value, start_time_offset_in_min, end_time_offset_in_min}}) 
			onClick_handleClose()
			fetch_bd_reps()
			toastr.success('Successfully updated BD Rep')
		}catch(e){
			console.error(e)
			toastr.error(`Couldn't update BD Rep`)
		}
	}

	const onClick_handleClose = () => {
		set_selected_user()
		toggle()
		set_selected_domain()
		set_selected_user()
		set_start_time('4:30')
		set_end_time('13:30')
	}

	useEffect(() => {
		if(active_bd_rep){
			set_selected_domain(infurnia_domains.find(o => o.value === active_bd_rep.domain))
			let user = cs_org_users && cs_org_users.length && cs_org_users.find(o => o.id === active_bd_rep.user_id)
			if(user){
				set_selected_user({value:user.id, label:user.first_name})
			}
			if(active_bd_rep.start_time_offset_in_min){
				let hrs = Math.floor(active_bd_rep.start_time_offset_in_min/60)
				let min = active_bd_rep.start_time_offset_in_min%60
				if(min == 0) min += '0'
				set_start_time(hrs + ':' + min)
			}
			if(active_bd_rep.end_time_offset_in_min){
				let hrs = Math.floor(active_bd_rep.end_time_offset_in_min/60)
				let min = active_bd_rep.end_time_offset_in_min%60
				if(min == 0) min += '0'
				set_end_time(hrs + ':' + min)
			}
		}
	}, [active_bd_rep]);

	useEffect(() => {
		console.log('valus', new Date(valus[0]), new Date(valus[1]))
	}, [valus]);

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onClick_handleClose}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" style={{}}>{mode==='add' ? 'Add' : 'Update'} BD Rep</h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onClick_handleClose}>
				<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" style={{fontSize:'14px'}}>
			<FormGroup>
				<Row>
                    <Col md="12">
						<div style={{position:'relative', zIndex:'5'}}>
							<label className="form-control-label" htmlFor="input-address" >
								Select Domain
							</label>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={selected_domain}
								// isMulti
								options={infurnia_domains}
								// styles={colourStyles}
								onChange={(selectedOptions) => set_selected_domain(selectedOptions)}
							/>
						</div>
                        {/* <Input name="credits" className="form-control-alternative" type="select" placeholder='Type...' value={selected_bd_reps} onChange={(e) => set_selected_bd_reps(e.target.value)} /> */}
						<div style={{position:'relative', zIndex:'4'}}>
							<label className="form-control-label mt-3" htmlFor="input-address" >
								Select User
							</label>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value = {selected_user}
								// isMulti
								options={cs_org_users && cs_org_users.length && cs_org_users.map(c => {return {'value': c.id, 'label': c.first_name}})}
								// styles={colourStyles}
								onChange={(selectedOptions) => set_selected_user(selectedOptions)}
								isDisabled={mode==='edit'}
							/>
						</div>
						<div style={{position:'relative', zIndex:'3'}}>
							<label className="form-control-label mt-3" htmlFor="input-address" >
								Working Hours (in GMT, 24 hr format HH:mm)
							</label>
							{/* <TimePicker.RangePicker 
								format = 'HH:mm' 
								// defaultOpenValue={'10:00'}
								onChange={(vals) => setValues(vals)}
      							value={[valus[0], valus[1]]}	
							/> */}
							<div className="flex_property">
								<Input className='standard_input' value={start_time} onChange={(e) => set_start_time(e.target.value)} placeholder = 'HH:mm' />
								<div className="mx-4">to</div>
								<Input className='standard_input' value={end_time} onChange={(e) => set_end_time(e.target.value)} placeholder = 'HH:mm'/>
							</div>
						</div>
                    </Col>
                  </Row>
			</FormGroup>
			</div>
			<div className="modal-footer" style={{height:'60px'}}>
				<Button color='primary' disabled={(selected_domain && selected_domain.value && selected_user && selected_user.value ? false : true)} onClick={mode==='add' ? add_bd_rep : update_bd_rep}>Submit</Button>
			</div>
		</Modal>
	)
}

const Bdreps = ({user_details, cs_org_users, fetch_infurnia_bd_reps, is_cs_admin, is_team_lead, infurnia_teams}) => {

	const [added_bd_reps, set_added_bd_reps] = useState([]);
	const [display_added_bd_reps, set_display_added_bd_reps] = useState([]);
	const [mode, set_mode] = useState('');
	const [edit_modal_open, set_edit_modal_open] = useState(false);
	const [active_bd_rep, set_active_bd_rep] = useState('');
	const [remaining_cs_users, set_remaining_cs_users] = useState();
	const [bd_groups, set_bd_groups] = useState([]);
	const [bd_group_map, set_bd_group_map] = useState();
	const [selected_load_type, set_selected_load_type] = useState({value:'work_time_load', label:'Work Time Load'});
	const [selected_bd_grps, set_selected_bd_grps] = useState();
	const [selected_domain, set_selected_domain] = useState({value:'all', label:'All'});
	const [selected_availability, set_selected_availability] = useState({value:'all', label:'All'});
	const [selected_availability_date, set_selected_availability_date] = useState(new Date().toISOString().split('T')[0]);
	const [availability_details, set_availability_details] = useState();
	const [load_details, set_load_details] = useState();
	const [sorted_by_domain, set_sorted_by_domain] = useState(false);
	const [sorted_by_bd_grp, set_sorted_by_bd_grp] = useState(false);
	const [sorted_by_load_wt, set_sorted_by_load_wt] = useState(false);
	const [sorted_by_load_nwt, set_sorted_by_load_nwt] = useState(false);
	const initializedStateCount = useRef(0)
	const [filter_json_from_backend, set_filter_json_from_backend] = useState();

	const toggle_edit_modal = () => set_edit_modal_open(!edit_modal_open)
	
	const fetch_bd_reps = async() => {
		try {
			let account_details = cs_org_users
			let resp = await general_fetch({url:'bd_representative/get'})
			
			resp = resp.bd_representatives
			if(resp && resp.length){
				for(let i=0;i<resp.length;i++){
					let user = account_details.find(o => o.id === resp[i].user_id)
					resp[i].first_name = user ? user.first_name : ''
					resp[i].email = user? user.email : ''
				}
				set_added_bd_reps(resp)
			}

			account_details = account_details.filter(x => !resp.find(o => o.user_id === x.id))
			set_remaining_cs_users(account_details)
			fetch_infurnia_bd_reps()//this is to refetch in admin.jsx
		} catch (err) {
			console.error(err)
		}
	}

	const fetch_bd_groups = async() => {
		try{
            var resp = await general_fetch({url: 'bd_group/get'});
			resp.bd_groups = resp.bd_groups.filter( x => !x.hidden)
			set_bd_groups(resp.bd_groups)
			// set_selected_bd_grps(resp.bd_groups && resp.bd_groups.length ? resp.bd_groups.map((x, idx) => {return {value:x.id, label:x.code}}) : [])
			let temp_map = {}
			if(resp.bd_groups && resp.bd_groups.length){
				resp.bd_groups.map(x => {if(!x.hidden) temp_map[x.id] = x.code})
			}
			console.log('bd_groupsmap', temp_map)
			set_bd_group_map(temp_map)
		}catch(err){
			console.error(err)
		}
	}

	const fetch_bd_load = async() => {
		try{
            let resp1 = await general_fetch({url:'bd_representative/get_load'})
			set_load_details(resp1)
		}catch(err){
			console.error(err)
		}
	}

	const fetch_availability_details = async (date) => {
		try{
			let body = {start_time: date, end_time: date}
			let resp = await general_fetch({url:"unavailability/get_in_duration", body})
			set_availability_details(resp)
		}catch(err){
			console.error(err)
		}
	}

	const filter_fn = () => {
		let bd_reps = JSON.parse(JSON.stringify(added_bd_reps))

		if(selected_domain.value != 'all'){
			bd_reps = bd_reps.filter(o => o.domain === selected_domain.value)
		}

		if(selected_bd_grps && selected_bd_grps.length){
			let selected_bd_grp_ids = selected_bd_grps && selected_bd_grps.length ? selected_bd_grps.map(x => x.value) : []
			bd_reps = bd_reps.filter(o => o.BD_Groups.some(item => selected_bd_grp_ids.includes(item)))
		}

		if(selected_availability.value === 'active'){
			bd_reps = bd_reps.filter(o => !(availability_details && availability_details.length && availability_details.find(item => item.user_id === o.user_id)))
		}else if(selected_availability.value === 'inactive'){
			bd_reps = bd_reps.filter(o => availability_details && availability_details.length && availability_details.find(item => item.user_id === o.user_id))
		}

		set_display_added_bd_reps(bd_reps)
	}

	const sort_by_domain = (asc) => {
		let bd_reps = JSON.parse(JSON.stringify(display_added_bd_reps))
		bd_reps.sort((a, b) => generic_sort(a, b, 'domain', asc ? 1 : -1, 'string'))
		set_display_added_bd_reps(bd_reps)
		set_sorted_by_domain(asc)
	}

	const bd_grp_sort = (a, b, order) => {
		let str1 = a.BD_Groups && a.BD_Groups.length ? a.BD_Groups.map((c, idx) => ((bd_group_map && bd_group_map[c] ? bd_group_map[c] : ''))).join(''):""
		let str2 = b.BD_Groups && b.BD_Groups.length ? b.BD_Groups.map((c, idx) => ((bd_group_map && bd_group_map[c] ? bd_group_map[c] : ''))).join(''):""
		if(str1 > str2){
			return 1*order
		}else{
			return -1*order
		}
	}

	const sort_by_bd_grp = (asc) => {
		let bd_reps = JSON.parse(JSON.stringify(display_added_bd_reps))
		bd_reps.sort((a,b) => bd_grp_sort(a,b,asc ? 1 : -1))
		set_display_added_bd_reps(bd_reps)
		set_sorted_by_bd_grp(asc)
	}

	const load_sort = (a, b, order, load_type) => {
		if(load_details){
			if(!load_details[a.email]) return -1*order
			if(!load_details[b.email]) return 1*order
			if(Number(load_details[a.email][load_type]) > Number(load_details[b.email][load_type])){
				return 1*order
			}else{
				return -1*order
			}
		}
	}

	const sort_by_load = (asc, load_type, state_var) => {
		let bd_reps = JSON.parse(JSON.stringify(display_added_bd_reps))
		bd_reps.sort((a,b) => load_sort(a,b,asc ? 1 : -1, load_type));
		set_display_added_bd_reps(bd_reps)
		const setterFunction = `set_${state_var}`;
    	eval(setterFunction)(asc);
	}

	useEffect(() => {
		if(cs_org_users && cs_org_users.length){
			fetch_bd_reps()
			fetch_bd_groups()
			fetch_bd_load()
		}
	}, [cs_org_users]);

	useEffect(() => {
		if(user_details && is_team_lead && !is_cs_admin && infurnia_teams && infurnia_teams.length > 0 && added_bd_reps && added_bd_reps.length > 0) {
			let team_members = []
			infurnia_teams.map(team => {
				if(team.team_lead_id === user_details.id){
					team_members = team_members.concat(team.users)
				}
			})
			if(!team_members.includes(user_details.id)){
				team_members.push(user_details.id)
			}
			set_added_bd_reps(added_bd_reps.filter(o => team_members.includes(o.user_id)))
		}
	}, [is_cs_admin, is_team_lead, infurnia_teams, added_bd_reps, user_details]);

	useEffect(() => {
		if(selected_availability_date){
			fetch_availability_details(selected_availability_date)
		}
	}, [selected_availability_date]);

	useEffect(() => {
		filter_fn()
	}, [selected_domain, selected_bd_grps, selected_load_type, selected_availability, added_bd_reps, availability_details]);

	useEffect(() => {
		if(initializedStateCount.current < 1){
			initializedStateCount.current += 1
		}else if(user_details && user_details.id){
			let filter_json = {selected_domain, selected_bd_grps, selected_availability}
			set_csp_filter(user_details.id, '/home/bd_reps', '0', filter_json)
		}
	}, [user_details, selected_domain, selected_bd_grps, selected_availability]);

	useEffect(() => {
		if(user_details && user_details.id) get_csp_filter(user_details.id, '/home/bd_reps', set_filter_json_from_backend)
	}, [user_details]);

	useEffect(() => {
		if(filter_json_from_backend && filter_json_from_backend['/home/bd_reps']){
			let final_json = JSON.parse(filter_json_from_backend['/home/bd_reps'][0])
			if(final_json){
				set_selected_domain(final_json.selected_domain ? final_json.selected_domain : {value:'all', label:'All'})
				set_selected_bd_grps(final_json.selected_bd_grps ? final_json.selected_bd_grps : [])
				set_selected_availability(final_json.selected_availability ? final_json.selected_availability : {value:'all', label:'All'}) 
			}
		}
	}, [filter_json_from_backend]);

	const LoadSplit = ({type, load_details}) => (
		<div className="p-2" >
			<div className="flex_between bold" style={{width:'100%'}}>
				<div style={{flex:5}}>Item</div>
				<div style={{flex:1}} className="mr-4 text-right">Count</div>
				<div style={{flex:1}} className="text-right">Score</div>
			</div>
			<div className="flex_between" style={{width:'100%'}}>
				<div style={{flex:5}}>Initial Demo Calls</div>
				<div style={{flex:1}} className="text-right mr-4">{load_details && load_details.initial_demo_calls}</div>
				<div style={{flex:1}} className="text-right">{load_details && load_details.initial_demo_calls ? Number(2*load_details.initial_demo_calls) : 0}</div>
			</div>
			<div className="flex_between" style={{width:'100%'}}>
				<div style={{flex:5}}>Prospecting Calls</div>
				<div style={{flex:1}} className="text-right mr-4">{load_details && load_details.prospecting_calls}</div>
				<div style={{flex:1}} className="text-right">{load_details && load_details.prospecting_calls}</div>
			</div>
			<div className="flex_between" style={{width:'100%'}}>
				<div style={{flex:5}}>Setup Demo Tasks</div>
				<div style={{flex:1}} className="text-right mr-4">{load_details && load_details.setup_demo_tasks}</div>
				<div style={{flex:1}} className="text-right">{load_details && load_details.setup_demo_tasks ? Number(Number(load_details.setup_demo_tasks)/2).toFixed(1) : 0}</div>
			</div>
			{
				type === 'non_work_time_load'?
				<div className="flex_between" style={{width:'100%'}}>
					<div style={{flex:5}}>DNP Prospecting Calls</div>
					<div style={{flex:1}} className="text-right mr-4">{load_details && load_details.dnp_prospecting_calls}</div>
					<div style={{flex:1}} className="text-right">{load_details && load_details.dnp_prospecting_calls}</div>
				</div>:''
			}
		</div>
	)

	return(
		<>
			<div className="px-4 pt-4">
				<Row className='mb-3 mr-3 flex_property' style={{justifyContent: 'space-between', fontSize:'14px', position:'relative', zIndex:'200'}}>
					<div className="flex_center">
						<div className="mr-2 ml-4">Domain:</div>
						<Select
							// closeMenuOnSelect={false}
							// components={animatedComponents}
							value={selected_domain}
							// isMulti
							options={[{value:'all', label:'All'}, {value: 'interior', label: 'Modular Interiors'}, {value: 'architecture', label: 'Architecture & Interiors'}]}
							styles={colourStyles}
							onChange={(selectedOptions) => set_selected_domain(selectedOptions)}
						/>
					</div>
					<div className="flex_center">
						{/* <div className="mr-2">Load:</div>
						<Select
							// closeMenuOnSelect={false}
							// components={animatedComponents}
							value={selected_load_type}
							// isMulti
							options={[{value:'work_time_load', label:'Work Time Load'}, {value:'non_work_time_load', label:'Non Work Time Load'}]}
							styles={colourStyles}
							onChange={(selectedOptions) => set_selected_load_type(selectedOptions)}
						/> */}
						{
							is_cs_admin?<Button className='ml-4' color='primary' onClick={() => {toggle_edit_modal(); set_mode('add')}}>Add BD Rep</Button>:''
						}
					</div>
				</Row>
				<Row className='mb-3 mr-3 flex_property' style={{justifyContent: 'space-between', fontSize:'14px'}}>
					<div className="flex_center ml-4">
						<div className="mr-2">BD Groups:</div>
						<Select
							// closeMenuOnSelect={false}
							components={animatedComponents}
							value={selected_bd_grps}
							isMulti
							options={bd_groups && bd_groups.length ? bd_groups.map((x, idx) => {return {value:x.id, label:x.code}}) : []}
							styles={colourStyles3}
							onChange={(selectedOptions) => set_selected_bd_grps(selectedOptions)}
						/>
					</div>
					<div className="flex_center">
						<div className="flex_center mr-2">
							<div className="mr-2">Availability:</div>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={selected_availability}
								// isMulti
								options={[{value: 'all', label: 'All'}, {value: 'active', label: 'Active'}, {value: 'inactive', label: 'Inactive'}]}
								styles={colourStyles}
								onChange={(selectedOptions) => set_selected_availability(selectedOptions)}
							/>
						</div>
						<DatePicker 
							format="YYYY-MM-DD"
							defaultValue = {selected_availability_date ? dayjs(selected_availability_date, 'YYYY-MM-DD') : null}
							onChange={(date, dateString) => set_selected_availability_date(dateString)}
						/>
					</div>
				</Row>
			</div>
			<Card className="shadow mx-2">
				<div style={{minHeight:'200px'}}>
					<Table className="align-items-center table-flush text-center" responsive style={{position:'relative'}}>
						<thead className="thead-light text-center" style={{position:'sticky', top:0}}>
							<tr style={{ textAlign: 'left' }}>
								<th scope="col"><span style={styles.table_header}><b>S.No</b></span>&nbsp;</th>
								<th scope="col"><span style={styles.table_header}><b>Name</b></span>&nbsp;</th>
								<th scope="col"><span style={styles.table_header}><b>Email</b></span>&nbsp;</th>
								<th scope="col"><span style={styles.table_header}><b>Domain <i onClick={() => sort_by_domain(!sorted_by_domain)} className="fa fa-sort cp"/></b></span>&nbsp;</th>
								<th scope="col"><span style={styles.table_header}><b>BD Groups <i onClick={() => sort_by_bd_grp(!sorted_by_bd_grp)} className="fa fa-sort cp"/></b></span>&nbsp;</th>
								<Tooltip title='Work Time Load' placement="top">
									<th scope="col"><span style={styles.table_header}><b>Load - WT <i onClick={() => sort_by_load(!sorted_by_load_wt, 'work_time_load', 'sorted_by_load_wt')} className="fa fa-sort cp"/></b></span>&nbsp;</th>
								</Tooltip>
								<Tooltip title='Non-Work Time Load' placement="top">
									<th scope="col"><span style={styles.table_header}><b>Load - NWT <i onClick={() => sort_by_load(!sorted_by_load_nwt, 'non_work_time_load', 'sorted_by_load_nwt')} className="fa fa-sort cp"/></b></span>&nbsp;</th>
								</Tooltip>
								<th scope="col"><span style={styles.table_header}><b>Availability</b></span>&nbsp;</th>
								{is_cs_admin ? <th scope="col"><span style={styles.table_header}><b>Actions</b></span>&nbsp;</th>  :''}
							</tr>
						</thead>
						<tbody style={{maxHeight:"calc(100vh - 200px)", overflow:'scroll'}}>
							{
								display_added_bd_reps && display_added_bd_reps.length ? display_added_bd_reps.map((user, idx) => (

									<tr className="display_onhover" style={{ textAlign: 'left' }}>
										<td scope="col"><span>{idx + 1}</span>&nbsp;</td>
										<td scope="col"><span>{user.first_name ? user.first_name : 'N/A'}</span>&nbsp;</td>
										<td scope="col"><span>{user.email ? user.email : 'N/A'}</span>&nbsp;</td>
										<td scope="col"><span>{user.domain ? infurnia_domains.find(o => o.value===user.domain) ? infurnia_domains.find(o => o.value===user.domain).label : user.domain : 'N/A'}</span>&nbsp;</td>
										<td scope="col" title={
											user.BD_Groups && user.BD_Groups.length ? user.BD_Groups.map((c, idx) => (
												(bd_group_map && bd_group_map[c] ? bd_group_map[c] : '')
											)).join(' '):"N/A"
										}>
										{
											user.BD_Groups && user.BD_Groups.length ? user.BD_Groups.map((c, idx) => (
												(bd_group_map && bd_group_map[c] ? bd_group_map[c] : '')
											)).join(' '):"N/A"
										}
										</td>
										<td scope="col">
											<Tooltip placement="top" color='white' overlayInnerStyle={{backgroundColor:'white', width:'280px', color:'black'}} title={<LoadSplit type='work_time_load' load_details={load_details && load_details[user.email]}  />}>
												<span className="cp">{load_details && load_details[user.email] ? load_details[user.email]['work_time_load'] : '0'}</span>
											</Tooltip>
										</td>
										<td scope="col">
											<Tooltip placement="top" color='white' overlayInnerStyle={{backgroundColor:'white', width:'280px', color:'black'}} title={<LoadSplit type='non_work_time_load' load_details={load_details && load_details[user.email]} />}>
												<span className="cp">{load_details && load_details[user.email] ? load_details[user.email]['non_work_time_load'] : '0'}</span>
											</Tooltip>
										</td>
										<td scope="col"><span>{availability_details && availability_details.length && availability_details.find(o => o.user_id === user.user_id) ? 'Inactive' : 'Active'}</span>&nbsp;</td>
										{
											is_cs_admin?
											<td scope="col">
												<i onClick={() => { set_active_bd_rep(user); toggle_edit_modal(); set_mode('edit')}} className="fa fa-edit p-2 cp invisible1"></i>
											</td>:''
										}
									</tr>
								)) : ''
							}
						</tbody>
					</Table>
				</div>
			</Card>
				{/* <AddCsExecutive open={add_modal_open} toggle={toggle_add_modal} users={remaining_cs_users}/> */}
				<AddBdRep open={edit_modal_open} toggle={() => {toggle_edit_modal(); set_mode('')}} active_bd_rep={active_bd_rep} cs_org_users={cs_org_users} mode={mode} fetch_bd_reps={fetch_bd_reps}/>
		</>
	)
}

export default Bdreps